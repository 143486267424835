



































import {Vue, Component} from 'vue-property-decorator';
import {UserModule} from '@/store/modules/user';
import {ApplicationModule} from '@/store/modules/application';
import {postExpoAccidentelle} from '@/api/exposition';
import {to} from '@/utils';

@Component({})
export default class ExpoAccidentelleView extends Vue {
    private description = '';

    private errorMessage = '';

    public created(): void {
        this.description = '';
        this.errorMessage = '';
    }

    private get computedIsOnError() {
        return !!this.errorMessage;
    }

    private async postNewExpo() {
        this.errorMessage = '';
        if (!this.description) {
            this.errorMessage = 'Vous devez décrire la situation !';
            return;
        }

        if (!UserModule.authenticated || !ApplicationModule.online) {
            this.errorMessage = 'Vous devez être connecté à internet !';
            return;
        }

        ApplicationModule.setLoading(true);
        const [, result] = await to(postExpoAccidentelle(this.description));
        ApplicationModule.setLoading(false);
        if (result === 'EMPTY_DESCRIPTION') {
            this.errorMessage = 'Vous devez décrire la situation !';
            return;
        }
        if (result !== 'OK') {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Une erreur inconnue s'est produite... Merci de réessayer.",
                timeout: 10000
            });
            return;
        }
        ApplicationModule.showSnackbar({
            displayed: true,
            color: 'success',
            text: 'Votre exposition accidentelle a été prise en compte.',
            timeout: 5000
        });
        this.$router.push('/');
    }
}
